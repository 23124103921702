import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage } from '../../components';

import ActionBarMaybe from './ActionBarMaybe';

import css from './BrandListingPage.module.css';

const BrandSectionHero = props => {
  const {
    title,
    listing,
    isOwnListing,
    noPayoutDetailsSetWithOwnListing,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith('brand-banner'))
    : [];

  return (
    <div className={css.sectionHero} data-testid="hero">
      <div className={css.imageWrapperForSectionHero}>
        {listing.id && isOwnListing ? (
          <div className='col'>
            {noPayoutDetailsSetWithOwnListing &&
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={listing}
                showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              />
            }
            {listing.id &&
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={listing}
              />
            }
          </div>
        ) : null}

        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
        />
      </div>
    </div>
  );
};

export default BrandSectionHero;
