import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber, formatMoneyRoundWithoutDecimals } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  H3,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
} from './BrandListingPage.shared';
const { UUID, Money } = sdkTypes;

import css from './BrandListingPage.module.css';
import BrandSectionHero from './BrandSectionHero';
import { fetchListingsBrandProducts } from './BrandListingPage.duck';
import BrandProductsLimitedSection from './BrandProductsSection/BrandProductsLimitedSection';

export const BrandListingPageComponent = props => {

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    config,
    onFetchListingsBrandProducts,
    showListingsBrandProductsError,
    fetchListingsBrandProductsInProgress,
    listingsBrandProducts
  } = props;

  useEffect(() => {
    if (!listingsBrandProducts?.length) {
      onFetchListingsBrandProducts(listingId, config, 5);
    }
  }, []);

  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError || showListingsBrandProductsError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id || fetchListingsBrandProductsInProgress) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && (processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const { formattedPrice } = priceData(price, config.currency, intl);
  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const {
    brandLocation,
    certificates,
    delivery,
    importDutiesInfo,
    nrOfEmployees,
    originateCountry,
    productGroup,
    productPriceRange,
    productType,
    returns,
    storeLabels,
    sustainabilityFactors,
    year
  } = publicData || {};

  const listingFieldsConfig = config.listing.listingFields;
  const marketplaceCurrency = config.currency;

  const getLabelsByOptions = (optionValues, listingKey) => {
    const options = listingFieldsConfig.find(c => c.key === listingKey).enumOptions;
    const labels = optionValues?.map(optionValue => {
      const matchingOption = options.find(option => option.option === optionValue);
      return matchingOption ? matchingOption.label : 'Option not found';
    });

    return labels?.join(', ');
  };

  const formattedPriceRange = productPriceRange ? (
    `${formatMoneyRoundWithoutDecimals(intl, new Money(productPriceRange.minPrice.amount, marketplaceCurrency))}
    -
    ${formatMoneyRoundWithoutDecimals(intl, new Money(productPriceRange.maxPrice.amount, marketplaceCurrency))}`
  ) : null;

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.root} topbar={topbar} footer={<FooterContainer />}>
        <BrandSectionHero
          title={title}
          listing={currentListing}
          isOwnListing={isOwnListing}
          onManageDisableScrolling={onManageDisableScrolling}
          noPayoutDetailsSetWithOwnListing={noPayoutDetailsSetWithOwnListing}
        />

        <div className={css.container}>
          <H3 as="h1">
            {title}
          </H3>

          {/* <SectionDisplays /> */}

          <div className={css.detailsSection}>
            <div className={css.leftColumn}>
              <p>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.about" />
                </strong>
                <br />
                {description}
              </p>
              <p>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.sustainableAndEthicalGuarantees" />
                </strong>
                <br />
                {getLabelsByOptions(sustainabilityFactors, "sustainabilityFactors")}
              </p>

              {certificates &&
                <p>
                  <strong>
                    <FormattedMessage id="EditBrandPreviewAndSubmitForm.certificates" />
                  </strong>
                  <br />
                  {certificates}
                </p>
              }
            </div>
            <div className={css.rightColumn}>
              <p className={css.item}>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.basedIn" />
                </strong>
                <span>{`${brandLocation?.city}, ${brandLocation?.country}`}</span>
              </p>
              <p className={css.item}>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.establishedIn" />
                </strong>
                <span>{year}</span>
              </p>

              {nrOfEmployees &&
                <p className={css.item}>
                  <strong>
                    <FormattedMessage id="EditBrandPreviewAndSubmitForm.nrOfEmployees" />
                  </strong>
                  <span>{nrOfEmployees}</span>
                </p>
              }
              {/* <p className={css.item}>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.nrOfShops" />
                </strong>
              </p> */}
              <p className={css.item}>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.productGroup" />
                </strong>
                <span>{getLabelsByOptions(productGroup, "productGroup")}</span>
              </p>
              <p className={css.item}>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.productType" />
                </strong>
                <span>{getLabelsByOptions(productType, "productType")}</span>

              </p>
              <p className={css.item}>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.productPriceRange" />
                </strong>
                <span>{formattedPriceRange}</span>
              </p>
              <p className={css.item}>
                <strong>
                  <FormattedMessage id="EditBrandPreviewAndSubmitForm.brandAttributes" />
                </strong>
                <span>{getLabelsByOptions(storeLabels, "storeLabels")}</span>
              </p>
            </div>
          </div>

          {/* <BrandProductsSection
            brandName={title}
            products={listingsBrandProducts}
            intl={intl} /> */}
          <BrandProductsLimitedSection
            brandId={currentListing?.id.uuid}
            brandName={currentListing?.attributes?.title}
            products={listingsBrandProducts?.slice(0, 5)}
            intl={intl}
          />


          {/* <SectionShops title={title} /> */}

          <p className={css.subTitle}>
            <FormattedMessage id="EditBrandPreviewAndSubmitForm.deliveryAndReturns" />
          </p>
          <p className={css.item}>
            <strong>
              <FormattedMessage id="EditBrandPreviewAndSubmitForm.sendingFrom" />
            </strong>
            <span>{originateCountry}</span>
          </p>
          <p className={css.item}>
            <strong>
              <FormattedMessage id="EditBrandPreviewAndSubmitForm.delivery" />
            </strong>
            <span>{delivery}</span>
          </p>
          <p className={css.item}>
            <strong>
              <FormattedMessage id="EditBrandPreviewAndSubmitForm.duties" />
            </strong>
            <span>{importDutiesInfo}</span>
          </p>
          <p className={css.item}>
            <strong>
              <FormattedMessage id="EditBrandPreviewAndSubmitForm.returns" />
            </strong>
            <span>{returns}</span>
          </p>
        </div>

      </LayoutSingleColumn>
    </Page >
  );
};

BrandListingPageComponent.defaultProps = {
  currentUser: null,
  showListingError: null,
};

BrandListingPageComponent.propTypes = {
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  showListingError: propTypes.error,
};

const EnhancedBrandListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <BrandListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    showListingsBrandProductsError,
    fetchListingsBrandProductsInProgress,
    listingsBrandProducts,
  } = state.BrandListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingError,
    showListingsBrandProductsError,
    fetchListingsBrandProductsInProgress,
    listingsBrandProducts,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onFetchListingsBrandProducts: (brandId, config, limit) => dispatch(fetchListingsBrandProducts(brandId, config, limit))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const BrandListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedBrandListingPage);

export default BrandListingPage;
